/* BM */


@font-face {
  font-family: 'Averta';
  src: url('./media/averta-regular.woff') format('woff');
  font-weight: normal;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  /* Regular */
  font-family: 'Averta';
  src: url('./media/averta-bold.woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Averta';
  src: url('./media/averta-semibolditalic-webfont.woff');
  font-weight: normal;
  font-style: italic;
}
.lightbulb{
  color: #fff;
  fill: #fff;
  filter: drop-shadow(0px 0px 7px rgb(255 255 255 / 1));
}
.ant-menu ul li .ant-menu-title-content{
  padding: 3px 0px;
}
.ant-menu ul li ul li .ant-menu-title-content{
  margin-left: 10px;
  padding: 3px 0px;
}
.ant-menu ul li ul li ul li .ant-menu-title-content{
  margin-left: 20px;
  font-size: 12px;
  padding: 0px 0px;
}

.float-flex {
  display: flex;
  justify-content: space-between;
}

body {
  font-family: "Averta";
  font-size: 16px;
}


ol {
  padding-left: 20px;
}

ol .img-bordered {
  margin-left: 0px;
}

.menuitem {
  padding: 0px 20px !important;
}

.ant-card-meta-title {
  white-space: inherit;

}

.ant-card-hoverable {
  padding: 2px;
}

.ant-card-hoverable:hover {
  border: 3px solid #1581e9;
  outline: #1581e9;
  padding: 0px;
}

.html img {

  max-width: 100%;
  height: auto;
}

.html iframe {
  max-width: 100%;
  aspect-ratio: 16 / 9;
  height: auto;
  min-height: 370px;
}

.img-bordered {
  display: inline-block;
  line-height: 0px;
  border: 1px solid #f0f0f0;
}

h4.ant-typography, div.ant-typography-h4, div.ant-typography-h4 > textarea, .ant-typography h4{
  font-size: 25px !important
}

.ant-menu-title-content{
  font-size: 16px;
}

p {
  line-height: 25px;
  color: #636363;
}

p strong {
  color: #202020;
}

.html li {
  padding: 5px;
}

.hlw {
  background-color:#ffe4002e;
}

.bmcard {
  width: 291px;
  display: block;
  height: 140px;
}

code {
  display: inline-block;
  margin: 0 0;
  padding: 0px 5px;
  font-size: 13px;
  color: #fff;
  background-color: #000;
  border: 1px solid var(--black-300);
  border-radius: 3px;
  overflow-wrap: break-word;
  line-height: 20px;
}

kbd {
  display: inline-block;
  margin: 0 0;
  padding: 0px 5px;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border: 1px solid var(--black-300);
  border-radius: 3px;
  overflow-wrap: break-word;
  line-height: 20px;
}


.bm-warning {
  color: #f12a46;
  border-top: 3px solid #f12a46;
  border-bottom: 3px solid #f12a46;
  display: block;
  padding: 15px 0px 15px 40px; /* Adjust padding to make space for the icon */
  font-weight: normal;
  font-size: 16px;
  margin: 15px 0px;
  opacity: 1;
  position: relative; /* Necessary for positioning the icon */
}



.bm-warning::before {
  content: url('https://support.bookmanager.com/imgs/warning.svg');
  filter: invert(28%) sepia(84%) saturate(3991%) hue-rotate(327deg) brightness(90%) contrast(205%);
  font-size: 24px; /* Adjust size of the icon */
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%); /* Vertically center the icon */
}


li strong{
  color: #202020;
}

li kbd strong, li code strong{
  color: #fff;
}
.dark li strong{
  color: #fff;
}


.bm-warning kbd {
  font-size: 16px;
  background-color: #f12a46;
}

.bm-warning strong {
  color: #f12a46;
}

.dark .htmlcolor p .bm-warning strong{
  color: #f12a46 !important;
}


/* SUCCESS */

.bm-success {
  color: #35be13 !important;
  border-top: 3px solid #35be13 !important;
  border-bottom: 3px solid #35be13 !important;
  display: block;
  padding: 15px 0px 15px 40px; /* Adjust padding to make space for the icon */
  font-weight: normal;
  font-size: 16px;
  margin: 15px 0px;
  opacity: 1;
  position: relative; /* Necessary for positioning the icon */
}

.bm-success::before {
  content: url('https://support.bookmanager.com/imgs/lightbulb.svg');
  filter: invert(43%) sepia(68%) saturate(1713%) hue-rotate(68deg) brightness(91%) contrast(96%);
  font-size: 24px; /* Adjust size of the icon */
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%); /* Vertically center the icon */
}




.bm-success kbd {
  font-size: 16px;
  background-color: #35be13 !important;
}

.bm-success strong {
  color: #35be13 !important;
}

.dark .htmlcolor p .bm-success strong{
  color: #35be13 !important;
}



.bm-notice kbd {
  font-size: 16px;
  background-color: #1890ff;
}

.bm-notice strong {
  color: #1890ff !important;
}


.bm-notice p strong {
  color: #1890ff !important;
}

.dark .htmlcolor p .bm-notice strong{
  color: #1890ff !important;
}


.bm-notice {
  color: #1890ff;
  border-top: 3px solid #1890ff;
  border-bottom: 3px solid #1890ff;
  display: block;
  padding: 15px 0px;
  font-size: 16px;
  font-weight: normal;
  margin: 15px 0px;
  opacity: 1;
}

.bm-notice {
  color: #1890ff; 
  border-top: 3px solid #1890ff;
  border-bottom: 3px solid #1890ff;
  display: block;
  padding: 15px 0px 15px 40px; /* Adjust padding to make space for the icon */
  font-weight: normal;
  font-size: 16px;
  margin: 15px 0px;
  opacity: 1;
  position: relative; /* Necessary for positioning the icon */
}

.bm-notice::before {
  content: url('https://support.bookmanager.com/imgs/info.svg');
  filter: invert(47%) sepia(91%) saturate(664%) hue-rotate(177deg) brightness(88%) contrast(109%);
  font-size: 24px; /* Adjust size of the icon */
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%); /* Vertically center the icon */
}


/* Overwrites */

body {
  background-color: #ffffff;
}
.ant-layout{
  background-color: #ffffff;
}






p {
  line-height: 25px;
  font-size: 16px;
}

p strong {
  color: #202020;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  overflow: hidden;
}

.navH{
  height: 90px;
}

.navWrapper {
  background-color: #001529;
  padding: 12px 20px;
  position: fixed;
  z-index: 1100;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #001529;
  transition: border-color 0.3s, background 0.3s;
}

.mobileWrapper {
  background-color: #001529;
  padding: 5px 10px;
  position: fixed;
  z-index: 1100;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #001529;
  transition: border-color 0.3s, background 0.3s;
}



.shim {
  height: 5px;
  display: block;
}

.layoutHeader {
  padding-bottom: 0px;
  background-color: #fcfcfc;
  margin: 0px 0px;
  padding: 20px 20px 0px 20px;
  border-bottom: 1px solid #eee;
}

.paginate {
  background-color: #fcfcfc;
  margin: 0px 0px;
  border-bottom: 1px solid #eee;
}

.layout {
  background-color: #fff;
  padding: 20px;
}

.clean-input .ant-input-affix-wrapper {
  border: 1px solid #fff !important;
}


/* OVERWRITES */

.ant-input-group-addon {
  border: none;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
  padding: 0 13px;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover {
  background-color: transparent;
}


/* MENU */


.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: auto;
  line-height: 20px;
  padding: 7px 0px;
  padding-right: 20px;
}

.ant-menu-item,
.ant-menu-submenu-title {
  white-space: pre-wrap;
}



.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: auto;
  line-height: 16px;
  overflow: visible;
  text-overflow: initial;
  white-space: pre-wrap;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 10px 35px 10px 30px !important;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>-submenu-title a {
  height: auto;
  line-height: 16px;
  font-size: 13px;
  padding: 10px 20px 10px 30px !important;
}

.ant-anchor-link-title {
  text-overflow: initial;
  white-space: pre-wrap;
  font-size: 14px;
  padding-left: 5px;
  padding-bottom: 2px;
}
.dark .ant-anchor-ink::before{
  width: 1px;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  border-bottom: 1px dashed #ddd;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #1890ff !important;
  color: #fff;
}

.ant-menu-sub.ant-menu-inline {
  padding: 0;
  background: rgba(0, 60, 115, 0.03);
  border-radius: 0;
  box-shadow: none;
}

.ant-checkbox-wrapper{
  font-size: 16px;
}

.ant-breadcrumb{
  font-size: 14px !important;
}

.ant-breadcrumb-link {
  font-weight: normal;
}
.ant-breadcrumb a {

  color: #1890ff;
}

.ant-breadcrumb-separator {
  font-weight: normal;
}

.paddingwrap {
  padding: 0px !important;
  margin-top: 0px !important;
}


.show {
  display: block;
}

.hide {
  display: none;
}

.page{
  padding: 40px;
}

.headerSearch .ant-input-affix-wrapper{
  border: none !important;
}
.headerSearch .ant-input-search-button{
  height: 30px;
}


.headerSearch .ant-input::placeholder{
  color: #5ca0d0 !important;
}

.pageOffset{
  margin-top: -30px;
}

table{
  width: 100% !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background: none;
}





@media only screen and (max-width: 1240px) {

  .html img {
    width: 100%;
    max-width: 1024px;
    height: auto;
  }
  
  .html iframe {
    width: 100%;
    max-width: 1024px;
    aspect-ratio: 16 / 9;
    height: auto;
    min-height: 370px;
  }

  .navH{
    height: 50px;
  }

  .pageOffset{
    margin-top: 0px;
  }

  input {
    font-size: 16px !important;
    line-height: 10px !important;
  }

  .adjust{
    top: 10px !important;
  }

  .page{
    padding: 20px;
   
  }


  .show {
    display: none;
  }

  .hide {
    display: block;
  }

  .typeNav {
    display: none;
  }

  .layoutWrapper{
    border: none !important;
    margin-bottom: -10px !important;
  }

  html, body{
   
  }




}

@media print {
  .navWrapper {
    display: none;
  }

  .ant-breadcrumb {
    display: none;
  }

  .ant-layout-sider {
    display: none;
  }

  .layoutWrapper {
    border: none !important;
  }

  .paddingwrap {
    padding: 0px !important
  }

}